<template>
  <div>
    <modal-task :task-data="taskData" />
    <modal-task-calendar />
    <task-add-new
      :is-add-new-task-sidebar-active.sync="isAddNewTaskSidebarActive"
      :id="id"
      :data="taskData"
      :user-options="userOptions"
      :client-options="clientOptions"
      :project-options="projectOptions"
      :contract-options="contractOptions"
      :tasktag-options="tasktagOptions"
      :taskstage-options="taskstageOptions"
      @refetch-data="refetchData"
    >
    </task-add-new>

    <modal-update
      :title="title"
      :enable="enable"
      :task-data="taskData"
      :user-options="userOptions"
      :tasktag-options="tasktagOptions"
      :taskstage-options="taskstageOptions"
      @refetch-data="refetchData"
    />

    <task-list-filter
      :client-filter.sync="clientFilter"
      :date-from-filter.sync="dateFromFilter"
      :date-to-filter.sync="dateToFilter"
      :taskstage-filter.sync="taskstageFilter"
      :tasktag-filter.sync="tasktagFilter"
      :admin-filter.sync="adminFilter"
      :sort-filter.sync="sortFilter"
      :client-options="clientOptions"
      :taskstage-options="taskstageOptions"
      :tasktag-options="tasktagOptions"
    />

    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="搜尋..."
              />
              <b-button variant="primary" @click="handleCreate">
                <span class="text-nowrap">新增工作指派</span>
              </b-button>

              <b-button
                variant="primary"
                v-b-modal.modal-task-calendar
                id="modal-task-calendar"
                class="d-inline-block ml-1 mobile-100"
              >
                <feather-icon icon="CalendarIcon" size="18" class="" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refTaskListTable"
        class="position-relative"
        :items="fetchTasks"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: id -->
        <template #cell(id)="data">
          <b-button
            variant="outline-primary"
            v-b-modal.modal-task
            @click="showModal(data.item)"
            :style="{
              color:
                data.item.stage.complete_bit == 1
                  ? data.item.stage.background_color + ' !important'
                  : data.item.is_important
                  ? 'red !important'
                  : '#25215d', border: '1px solid white !important'
            }"
          >
            <span class="text-nowrap">{{ data.item.id }}</span>
          </b-button>
        </template>

        <!-- Column: Client Code -->
        <template #cell(client)="data">
          <b-link
            v-if="data.item.client"
            style="text-decoration: underline"
            :to="{
              name: 'clients-detail',
              params: { id: data.item.client_id },
            }"
            >{{ data.item.client.client_code }} {{data.item.client.company_name_cn}}</b-link
          >
        </template>
        <template #cell(contract_id)="data">
          <span v-if="data.item.contract">{{
            data.item.contract.contract_id
          }}</span>
        </template>

        <!-- Column: users -->
        <template #cell(users)="data">
          <!--
          <b-badge
            class="text-capitalize"
            v-for="item in getAssignee(data.item)"
            :key="item.id"
            variant="primary"
          >
            
          </b-badge>-->
          <p v-for="item in getAssignee(data.item)" :key="item.id">
            <b-badge
              @click="
                openUpdateModal(data.item, 'Update Assignee', {
                  is_tag: 0,
                  is_status: 0,
                  is_assignee: 1,
                })
              "
            >
              {{ item.name }}
            </b-badge>
          </p>
        </template>
        <!-- Column: stage -->
        <template #cell(stage)="data">
          <b-badge
            v-if="data.item.stage"
            :style="{
              background: data.item.stage.background_color,
              color: data.item.stage.font_color,
            }"
            @click="
              openUpdateModal(data.item, 'Update Status', {
                is_tag: 0,
                is_status: 1,
                is_assignee: 0,
              })
            "
          >
            {{ data.item.stage.stage }}
          </b-badge>
        </template>
        <!-- Column: Deadline -->
        <template #cell(deadline_date)="data">
          <b-badge
            v-if="data.item.deadline_bit"
            :style="{
              background:
                data.item.stage.complete_bit == 1
                  ? data.item.stage.background_color + ' !important'
                  : data.item.is_important
                  ? 'red'
                  : getDateDifference(data.item.deadline_date) < 7
                  ? 'Orange'
                  : 'Green',
              color: 'white',
            }"
          >
            {{ data.item.deadline_date }}<br />
            <span v-if="data.item.stage.complete_bit != 1"
              >{{
                getDateDifference(data.item.deadline_date).toFixed(2)
              }}
              Days</span
            >
          </b-badge>
        </template>
        <!-- Column: tag -->
        <template #cell(tag)="data">
          <b-badge
            v-if="data.item.tag"
            :style="{
              background: data.item.tag.background_color,
              color: data.item.tag.font_color,
            }"
            @click="
              openUpdateModal(data.item, 'Update Tag', {
                is_tag: 1,
                is_status: 0,
                is_assignee: 0,
              })
            "
          >
            {{ data.item.tag.tag }}
          </b-badge>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="handleEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'tasks-edit', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Update</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-if="ability.can('delete', 'tasks')"
              @click="confirmDelete(data.item.id)"
            >
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTasks"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import taskStoreModule from "../taskStoreModule";
import useTasksList from "./useTasksList";
import TaskAddNew from "./TaskListAddNew.vue";
import Ripple from "vue-ripple-directive";
import ModalTask from "../tasks-detail/ModalTask.vue";
import TaskListFilter from "./TaskListFilter.vue";
import router from "@/router";
import ModalUpdate from "../modal/ModalUpdate.vue";
import ModalTaskCalendar from "./modal/ModalCalendar.vue";

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    TaskAddNew,
    ModalTask,
    TaskListFilter,
    ModalUpdate,
    ModalTaskCalendar,
  },
  methods: {
    openUpdateModal(item, title, enable) {
      this.title = title;
      this.enable = enable;
      this.taskData = { ...item };
      this.$bvModal.show("modal-update");
    },
    getAssignee(task) {
      var create_id = task.create_id;
      return task.users.filter((item) => item.id != create_id);
    },
    getDateDifference(checkDate) {
      var date1 = new Date(checkDate);
      var date2 = new Date();
      var difference_in_time = date1.getTime() - date2.getTime();
      var difference_in_days = difference_in_time / (1000 * 3600 * 24);
      return difference_in_days;
    },
    getDateColor(item) {},
    getNumberFormat(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HKD",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },
    handleCreate() {
      this.id = 0;
      this.resetTaskData();
      this.isAddNewTaskSidebarActive = true;
    },
    showModal(item) {
      this.taskData = item;
    },
    handleEdit(item) {
      this.taskData = { ...item };
      this.id = item.id;
      console.log("taskData", this.taskData);
      this.isAddNewTaskSidebarActive = true;
    },
    resetTaskData() {
      this.taskData = {
        id: 0,
        deadline_bit: true,
        deadline_date: "",
        client_id: null,
        project_id: null,
        contract_id: null,
        users: null,
        title: "",
        content: "",
        task_stage_id: 1,
        task_tag_id: null,
        is_important: false,
      };
    },
    confirmDelete(id) {
      this.$swal({
        title: "確認刪除?",
        text: "Your action is final and you will not be able to retrieve the task.",
        showCancelButton: true,
        confirmButtonText: "刪除",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$store
            .dispatch("task/deleteTask", { id: id })
            .then((response) => {
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.refetchData();
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
  },
  data() {
    return {
      id: 0,
      title: "",
      enable: { is_tag: 0, is_status: 0, is_assignee: 0 },
      taskData: {},
    };
  },
  setup() {
    const TASK_STORE_MODULE_NAME = "task";

    if (!store.hasModule(TASK_STORE_MODULE_NAME))
      store.registerModule(TASK_STORE_MODULE_NAME, taskStoreModule);

    onUnmounted(() => {
      if (store.hasModule(TASK_STORE_MODULE_NAME))
        store.unregisterModule(TASK_STORE_MODULE_NAME);
    });

    const isAddNewTaskSidebarActive = ref(false);
    const clientOptions = ref([]);
    const contractOptions = ref([]);
    const projectOptions = ref([]);
    const userOptions = ref([]);
    const taskstageOptions = ref([]);
    const tasktagOptions = ref([]);

    store
      .dispatch(TASK_STORE_MODULE_NAME + "/fetchOptions", {
        project: true,
        contract: true,
        client: true,
        user: true,
        taskstage: true,
        tasktag: true,
      })
      .then((response) => {
        clientOptions.value = response.data.clients;
        projectOptions.value = response.data.projects;
        contractOptions.value = response.data.contracts;
        userOptions.value = response.data.users;
        taskstageOptions.value = response.data.taskstages;
        tasktagOptions.value = response.data.tasktags;
      })
      .catch((error) => {
        console.log(error.response);
      });

    //console.log('Route Name: ',router.currentRoute.name)
    const {
      fetchTasks,
      tableColumns,
      perPage,
      currentPage,
      totalTasks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTaskListTable,
      refetchData,
      ability,
      dateFromFilter,
      dateToFilter,
      clientFilter,
      taskstageFilter,
      tasktagFilter,
      sortFilter,
      adminFilter,
    } = useTasksList();

    return {
      //Sidebar
      isAddNewTaskSidebarActive,

      fetchTasks,
      tableColumns,
      perPage,
      currentPage,
      totalTasks,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTaskListTable,
      refetchData,
      avatarText,
      ability,
      //Options
      clientOptions,
      contractOptions,
      projectOptions,
      userOptions,
      tasktagOptions,
      taskstageOptions,
      dateFromFilter,
      dateToFilter,
      clientFilter,
      taskstageFilter,
      tasktagFilter,
      sortFilter,
      adminFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.badge {
  width: 100%;
}
.status-important {
  background: red !important;
}
</style>
