import axiosIns from '@/libs/axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
      fetchTask(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get(`/task`, {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
      },
      fetchTasks(ctx, queryParams) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/task/list', {params:queryParams})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
      },
      addTask(ctx, data) {
        return new Promise((resolve, reject) => {
            axiosIns.post('/task', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      updateTask(ctx,data) {
          return new Promise((resolve, reject) => {
            axiosIns.post('/task/update', data)
            .then(response => resolve(response))
            .catch(error => reject(error))
          })
      }
      ,deleteTask(ctx, data) {
        return new Promise((resolve, reject) => {
          axiosIns.delete('/task', {params:data})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
      fetchOptions(ctx, data) {
        return new Promise((resolve, reject) => {
          axiosIns.get('/option/list', {params:data})
          .then(response => resolve(response))
          .catch(error => reject(error))
        })
      },
      deleteTaskRecord(ctx, data) {
        return new Promise((resolve, reject) => {
          axiosIns.delete('/task_record', {params:data})
            .then(response => resolve(response))
            .catch(error => reject(error))
        })
      },
    },
  }