<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        過濾器
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>客戶</label>
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :value="clientFilter"
              :filter="clientSearch"
              :options="clientOptions"
              label="client_code"
              class="w-100"
              :reduce="(val) => val.id"
              @input="(val) => $emit('update:clientFilter', val)"
            >
            <template #option="{client_code, company_name_cn}">
              [{{ client_code }}] {{ company_name_cn }}
            </template>
             <template #selected-option="{client_code, company_name_cn}">
              [{{ client_code }}] {{ company_name_cn }}
            </template>
            </v-select>
          </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>日期由</label>
            <b-form-datepicker
            id="date_from"
            placeholder="日期由"
            local="en"
            :value="dateFromFilter"
            @input="(val) => $emit('update:dateFromFilter', val)"
            :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            }"
        />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>日期至</label>
            <b-form-datepicker
            id="date_to"
            placeholder="日期至"
            local="en"
            :value="dateToFilter"
            @input="(val) => $emit('update:dateToFilter', val)"
            :date-format-options="{
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            }"
        />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>狀態</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="taskstageFilter"
            :options="taskstageOptions"
            label="stage"
            class="w-100"
            :reduce="val => val.id"
            @input="(val) => $emit('update:taskstageFilter', val)"
          >
          <template #option="{ stage, background_color }">
                  <div
                    class="rounded-circle d-inline-block mr-50 bg-primary"
                    :style="{
                      background: background_color + ' !important',
                      height: '10px',
                      width: '10px',
                    }"
                  />
                  <span> {{ stage }}</span>
                </template>
  
                <template #selected-option="{ stage, background_color }">
                  <div
                    class="rounded-circle d-inline-block mr-50 bg-primary"
                    :style="{
                      background: background_color + ' !important',
                      height: '10px',
                      width: '10px',
                    }"
                  />
                  <span> {{ stage }}</span>
                </template>
          </v-select>
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>標籤</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="tasktagFilter"
            :options="tasktagOptions"
            label="tag"
            class="w-100"
            :reduce="val => val.id"
            @input="(val) => $emit('update:tasktagFilter', val)"
          >
          <template #option="{ tag, background_color }">
                  <div
                    class="rounded-circle d-inline-block mr-50 bg-primary"
                    :style="{
                      background: background_color + ' !important',
                      height: '10px',
                      width: '10px',
                    }"
                  />
                  <span> {{ tag }}</span>
                </template>
  
                <template #selected-option="{ tag, background_color }">
                  <div
                    class="rounded-circle d-inline-block mr-50 bg-primary"
                    :style="{
                      background: background_color + ' !important',
                      height: '10px',
                      width: '10px',
                    }"
                  />
                  <span> {{ tag }}</span>
                </template>
          </v-select>
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2">
        <label></label>
        <div>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                variant="primary"
                @click="reset"
                style="height:40px;"
              >
                Reset
              </b-button>
              </div>
        </b-col>
        <b-col cols="12" md="2" class="mb-md-0 mb-2" v-if="false">
        <label></label>
        <div class="d-flex align-items-center justify-content-end">
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                size="sm"
                :variant="(sortFilter)?'success':''"
                @click="triggerSorting()"
                style="height:40px;"
              >
                自訂排序
              </b-button>
              </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BFormDatepicker, BButton
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from "vue-ripple-directive";
import router from "@/router";    


export default {
    directives: {
    Ripple,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    vSelect,
    BFormDatepicker,
    BButton,
  },
  mounted(){
    if(this.$router.currentRoute.name == 'admin-tasks')
    {
      this.$emit('update:adminFilter', true)
    }
  },
  watch: {
    $route (to, from){
        console.log('route', to, from)
        if(to.name == 'admin-tasks')
        {
          this.$emit('update:adminFilter', true)
        }else if(to.name == 'tasks')
        {
          this.$emit('update:adminFilter', false)
        }
    }
  },
  methods:{
      reset(){ 
        this.$emit('update:dateFromFilter', null)
        this.$emit('update:dateToFilter', null)
        this.$emit('update:clientFilter', null)
        this.$emit('update:taskstageFilter', null)
        this.$emit('update:tasktagFilter', null)
        this.$emit('update:sortFilter', false)
      },triggerSorting()
      {
        this.$emit('update:sortFilter', !this.sortFilter)
      },
      clientSearch(options, search){
        var returnOptions = options.filter(item => {
          return item.client_code.toLowerCase().includes(search.toLowerCase()) || item.company_name_cn.toLowerCase().includes(search.toLowerCase()) || item.company_name_en.toLowerCase().includes(search.toLowerCase())
        })
          return returnOptions
      }
  },
  props: {
    clientFilter: {
      type: [Number, null],
      default: null,
    },
    taskstageFilter: {
      type: [Number, null],
      default: null,
    },
    tasktagFilter: {
      type: [Number, null],
      default: null,
    },
    dateFromFilter:{
        type:[String, null],
        default:null,
    },
    dateToFilter:{
        type:[String, null],
        default: null,
    },
    sortFilter:{
        type:[Boolean, null],
        default: false,
    },
    adminFilter:{
        type:[Boolean, null],
        default:false,
    },
    clientOptions: {
      type: Array,
      required: true,
    },
    taskstageOptions:{
        type:Array,
        required: true,
    },
    tasktagOptions:{
        type: Array,
        required: true,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
