<template>
  <b-modal
    id="modal-task"
    ok-title="submit"
    cancel-variant="outline-secondary"
    scrollable
    size="lg"
    title="Task Detail"
    hide-footer
  >
    <b-card>
      <b-card-body>
        <h4>{{ taskData.title }}</h4>
        <h5 v-if="taskData.deadline_bit">
          Deadline: {{ taskData.deadline_date }}
        </h5>
        <h5 v-if="taskData.creator">Assigner: <span class="ml-1">{{ taskData.creator.name }}</span></h5>
        <h5 v-if="taskData.users">
          Assignee:
          <span class="ml-1" v-for="item in taskData.users" :key="item.name">{{
            item.name
          }}</span>
        </h5>
        <h6>Remark:</h6>
        <div v-html="taskData.content"></div>
        <hr />
        <app-timeline>
          <app-timeline-item
            v-for="item in taskData.contents"
            :key="item.id"
          >
            <small class="text-muted">{{ item.record_date }} [{{ item.user.name }}]</small>
            <div v-html="item.remark"></div>
          </app-timeline-item>
        </app-timeline>
      </b-card-body>
    </b-card>
  </b-modal>
</template>

<script>
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import { BModal, BCard, BCardBody, BBadge } from "bootstrap-vue";

export default {
  components: {
    BModal,
    BCard,
    BCardBody,
    BBadge,
    AppTimeline,
    AppTimelineItem,
  },
  methods: {
    hide() {
      this.$bvModal.hide("modal-task");
    },
  },
  props: {
    taskData: {
      type: Object,
      required: true,
    },
  },
};
</script>