var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-modal',{attrs:{"id":"modal-update","ok-title":"submit","cancel-variant":"outline-secondary","size":"sm","centered":"","title":_vm.title,"no-close-on-backdrop":""},on:{"ok":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('b-overlay',{attrs:{"show":_vm.loading,"spinner-variant":"primary","spinner-type":"grow","spinner-small":"","rounded":"sm"}},[_c('b-form',{staticClass:"mt-1",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.enable.is_status)?_c('validation-provider',{attrs:{"name":"Task Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Task Status","label-for":"task_stage_id"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.taskstageOptions,"label":"stage","reduce":function (option) { return option.id; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var stage = ref.stage;
var background_color = ref.background_color;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50 bg-primary",style:({
                    background: background_color + ' !important',
                    height: '10px',
                    width: '10px',
                  })}),_c('span',[_vm._v(" "+_vm._s(stage))])]}},{key:"selected-option",fn:function(ref){
                  var stage = ref.stage;
                  var background_color = ref.background_color;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50 bg-primary",style:({
                    background: background_color + ' !important',
                    height: '10px',
                    width: '10px',
                  })}),_c('span',[_vm._v(" "+_vm._s(stage))])]}}],null,true),model:{value:(_vm.taskData.task_stage_id),callback:function ($$v) {_vm.$set(_vm.taskData, "task_stage_id", $$v)},expression:"taskData.task_stage_id"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}):_vm._e(),(_vm.enable.is_tag)?_c('b-form-group',{attrs:{"label":"Tag","label-for":"task_tag_id"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.tasktagOptions,"label":"tag","reduce":function (option) { return option.id; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                  var tag = ref.tag;
                  var background_color = ref.background_color;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50 bg-primary",style:({
                    background: background_color + ' !important',
                    height: '10px',
                    width: '10px',
                  })}),_c('span',[_vm._v(" "+_vm._s(tag))])]}},{key:"selected-option",fn:function(ref){
                  var tag = ref.tag;
                  var background_color = ref.background_color;
return [_c('div',{staticClass:"rounded-circle d-inline-block mr-50 bg-primary",style:({
                    background: background_color + ' !important',
                    height: '10px',
                    width: '10px',
                  })}),_c('span',[_vm._v(" "+_vm._s(tag))])]}}],null,true),model:{value:(_vm.taskData.task_tag_id),callback:function ($$v) {_vm.$set(_vm.taskData, "task_tag_id", $$v)},expression:"taskData.task_tag_id"}})],1):_vm._e(),(_vm.enable.is_assignee)?_c('validation-provider',{attrs:{"name":"Assignee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(validationContext){return [_c('b-form-group',{attrs:{"label":"Assignee","label-for":"users"}},[_c('v-select',{attrs:{"multiple":"","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.userOptions,"label":"name","reduce":function (option) { return option.id; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
                  var avatar = ref.avatar;
                  var name = ref.name;
return [_c('b-avatar',{attrs:{"size":"26","src":avatar,"text":_vm.avatarText(name)}}),_c('span',{staticClass:"ml-50 d-inline-block align-middle"},[_vm._v(" "+_vm._s(name))])]}}],null,true),model:{value:(_vm.taskData.users),callback:function ($$v) {_vm.$set(_vm.taskData, "users", $$v)},expression:"taskData.users"}}),_c('b-form-invalid-feedback',{attrs:{"state":_vm.getValidationState(validationContext)}},[_vm._v(" "+_vm._s(validationContext.errors[0])+" ")])],1)]}}],null,true)}):_vm._e()],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }