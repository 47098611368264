<template>
  <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
    <b-modal
      id="modal-update"
      ok-title="submit"
      cancel-variant="outline-secondary"
      size="sm"
      centered
      :title="title"
      no-close-on-backdrop
      @ok.prevent="handleSubmit(onSubmit)"
    >
      <b-overlay
        :show="loading"
        spinner-variant="primary"
        spinner-type="grow"
        spinner-small
        rounded="sm"
      >
        <b-form class="mt-1" @submit.prevent="handleSubmit(onSubmit)">
          <validation-provider
            #default="validationContext"
            name="Task Status"
            rules="required"
            v-if="enable.is_status"
          >
            <b-form-group label="Task Status" label-for="task_stage_id">
              <v-select
                v-model="taskData.task_stage_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="taskstageOptions"
                label="stage"
                :reduce="(option) => option.id"
              >
              <template #option="{ stage, background_color }">
                  <div
                    class="rounded-circle d-inline-block mr-50 bg-primary"
                    :style="{
                      background: background_color + ' !important',
                      height: '10px',
                      width: '10px',
                    }"
                  />
                  <span> {{ stage }}</span>
                </template>
  
                <template #selected-option="{ stage, background_color }">
                  <div
                    class="rounded-circle d-inline-block mr-50 bg-primary"
                    :style="{
                      background: background_color + ' !important',
                      height: '10px',
                      width: '10px',
                    }"
                  />
                  <span> {{ stage }}</span>
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <b-form-group label="Tag" label-for="task_tag_id" v-if="enable.is_tag">
            <v-select
              v-model="taskData.task_tag_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tasktagOptions"
              label="tag"
              :reduce="(option) => option.id"
            >
            <template #option="{ tag, background_color }">
                  <div
                    class="rounded-circle d-inline-block mr-50 bg-primary"
                    :style="{
                      background: background_color + ' !important',
                      height: '10px',
                      width: '10px',
                    }"
                  />
                  <span> {{ tag }}</span>
                </template>
  
                <template #selected-option="{ tag, background_color }">
                  <div
                    class="rounded-circle d-inline-block mr-50 bg-primary"
                    :style="{
                      background: background_color + ' !important',
                      height: '10px',
                      width: '10px',
                    }"
                  />
                  <span> {{ tag }}</span>
                </template>
            </v-select>
          </b-form-group>
          <validation-provider
            #default="validationContext"
            name="Assignee"
            rules="required"
            v-if="enable.is_assignee"
          >
            <b-form-group label="Assignee" label-for="users">
              <v-select
                multiple
                v-model="taskData.users"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="userOptions"
                label="name"
                :reduce="(option) => option.id"
              >
                <template #option="{ avatar, name }">
                  <b-avatar size="26" :src="avatar" :text="avatarText(name)" />
                  <span class="ml-50 d-inline-block align-middle">
                    {{ name }}</span
                  >
                </template>
              </v-select>
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-form>
      </b-overlay>
    </b-modal>
  </validation-observer>
</template>
  
  <script>
import {
  BModal,
  VBModal,
  BCardText,
  BOverlay,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BCardBody,
  BCard,
  BFormInvalidFeedback,
  BAvatar,
} from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import { ref, onUnmounted } from "@vue/composition-api";
import store from "@/store";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import formValidation from "@core/comp-functions/forms/form-validation";
import { avatarText } from '@core/utils/filter'

export default {
  data() {
    return {
        avatarText
    };
  },
  components: {
    BModal,
    VBModal,
    BCardText,
    BOverlay,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    BCardBody,
    BCard,
    vSelect,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BAvatar,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    enable:{
        type: Object,
        required: true
    },
    taskData: {
      type: Object,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    tasktagOptions: {
      type: Array,
      required: true,
    },
    taskstageOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    onSubmit() {
      this.$swal({
        title: "確認儲存?",
        showCancelButton: true,
        confirmButtonText: "儲存",
        cancelButtonText: '取消',
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          var users = [];
          this.taskData.users.forEach((item) => {
            if (typeof item == "object") {
              users.push(item.id);
            } else {
              users.push(item);
            }
          });
          this.taskData.users = users;

          store
            .dispatch("task/addTask", this.taskData)
            .then((response) => {
              this.loading = false;
              this.$swal({
                text: response.data.message,
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
              this.$emit("refetch-data");
              this.hide();
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    hide() {
      this.$bvModal.hide("modal-update");
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);
    const resettaskData = () => {
      props.taskData = JSON.parse(JSON.stringify({}));
    };

    const itemFormBlankItem = {
      id: 0,
      project_stage_id: null,
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resettaskData);

    return {
      loading,
      refFormObserver,
      getValidationState,
      resetForm,
      itemFormBlankItem,
    };
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  