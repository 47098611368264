<template>
  <b-sidebar
    id="add-new-task-sidebar"
    :visible="isAddNewTaskSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-task-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">{{ button_text }}工作指派</h5>
        <div>
          <span
            v-if="taskData.is_important"
            :class="{ 'text-danger': taskData.is_important }"
            >Urgent Task</span
          >
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="AlertTriangleIcon"
            size="20"
            :class="{ 'text-danger': taskData.is_important }"
            @click="taskData.is_important = !taskData.is_important"
          />
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
      </div>

      <!-- Body -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-overlay
          :show="loading"
          spinner-variant="primary"
          spinner-type="grow"
          spinner-small
          rounded="sm"
        >
          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <!-- 客戶 -->
            <b-form-group label="客戶" label-for="client_id">
              <v-select
                v-model="taskData.client_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="clientOptions"
                label="client_code"
                :reduce="(option) => option.id"
              >
                <template #option="{ company_name_cn, client_code }">
                  <span class="d-inline-block align-middle">
                    [{{ client_code }}]{{ company_name_cn }}</span
                  >
                </template>
              </v-select>
            </b-form-group>
            <!-- 合約 -->
            <b-form-group label="合約" label-for="contract_id">
              <v-select
                v-model="taskData.contract_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="getContracts()"
                label="contract_code"
                :reduce="(option) => option.id"
              >
              </v-select>
            </b-form-group>
            <!-- 項目 -->
            <b-form-group label="項目" label-for="project_id">
              <v-select
                v-model="taskData.project_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="getProjects()"
                label="project_id"
                :reduce="(option) => option.id"
              >
              </v-select>
            </b-form-group>

            <!-- 指派工作標題-->
            <validation-provider
              #default="validationContext"
              name="指派工作標題"
              rules="required"
            >
              <b-form-group label="指派工作標題" label-for="task">
                <b-form-input
                  id="task"
                  v-model="taskData.title"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Remark -->
            <!-- 設置限期 -->
            <b-form-group>
              <b-form-checkbox
                checked="true"
                name="check-button"
                switch
                inline
                v-model="taskData.deadline_bit"
              >
                設置限期
              </b-form-checkbox>
            </b-form-group>
            <validation-provider
              #default="validationContext"
              name="限期"
              rules="required"
              v-if="taskData.deadline_bit"
            >
              <b-form-group label="限期" label-for="due-date">
                <flat-pickr
                  v-model="taskData.deadline_date"
                  class="form-control"
                />
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="指定員工"
              rules="required"
            >
              <b-form-group label="指定員工" label-for="users">
                <v-select
                  multiple
                  v-model="taskData.users"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="userOptions"
                  label="name"
                  :reduce="(option) => option.id"
                >
                  <template #option="{ avatar, name }">
                    <b-avatar
                      size="26"
                      :src="avatar"
                      :text="avatarText(name)"
                    />

                    <span class="ml-50 d-inline-block align-middle">
                      {{ name }}</span
                    >
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <validation-provider
              #default="validationContext"
              name="狀態"
              rules="required"
            >
              <b-form-group label="狀態" label-for="task_stage_id">
                <v-select
                  v-model="taskData.task_stage_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="taskstageOptions"
                  label="stage"
                  :reduce="(option) => option.id"
                >
                  <template #option="{ stage, background_color }">
                    <div
                      class="rounded-circle d-inline-block mr-50 bg-primary"
                      :style="{
                        background: background_color + ' !important',
                        height: '10px',
                        width: '10px',
                      }"
                    />
                    <span> {{ stage }}</span>
                  </template>

                  <template #selected-option="{ stage, background_color }">
                    <div
                      class="rounded-circle d-inline-block mr-50 bg-primary"
                      :style="{
                        background: background_color + ' !important',
                        height: '10px',
                        width: '10px',
                      }"
                    />
                    <span> {{ stage }}</span>
                  </template>
                </v-select>
                <b-form-invalid-feedback
                  :state="getValidationState(validationContext)"
                >
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <b-form-group label="標籤" label-for="task_tag_id">
              <v-select
                v-model="taskData.task_tag_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tasktagOptions"
                label="tag"
                :reduce="(option) => option.id"
              >
                <template #option="{ tag, background_color }">
                  <div
                    class="rounded-circle d-inline-block mr-50 bg-primary"
                    :style="{
                      background: background_color + ' !important',
                      height: '10px',
                      width: '10px',
                    }"
                  />
                  <span> {{ tag }}</span>
                </template>

                <template #selected-option="{ tag, background_color }">
                  <div
                    class="rounded-circle d-inline-block mr-50 bg-primary"
                    :style="{
                      background: background_color + ' !important',
                      height: '10px',
                      width: '10px',
                    }"
                  />
                  <span> {{ tag }}</span>
                </template>
              </v-select>
            </b-form-group>
            <!-- 指派工作內容 -->
            <validation-provider
              #default="validationContext"
              name="指派工作內容"
              rules="required"
            >
              <b-form-group label="指派工作內容" label-for="content">
                <b-form-textarea
                  id="content"
                  v-model="taskData.content"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  style="display: none"
                />
                <quill-editor
                  id="quil-content"
                  v-model="taskData.content"
                  :options="editorOption"
                  class="border-bottom-0"
                />
                <div
                  id="quill-toolbar"
                  class="d-flex justify-content-end border-top-0"
                >
                  <!-- Add a bold button -->
                  <button class="ql-bold" />
                  <button class="ql-italic" />
                  <button class="ql-underline" />
                  <button class="ql-align" />
                  <button class="ql-link" />
                </div>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            <!-- Form Actions -->

            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ button_text }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                取消
              </b-button>
            </div>
          </b-form>
        </b-overlay>
      </validation-observer>
    </template></b-sidebar
  >
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormCheckbox,
  BOverlay,
  BAvatar,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref, watch } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import { max } from "@/libs/validations.js";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import flatPickr from "vue-flatpickr-component";
import { quillEditor } from "vue-quill-editor";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    BFormCheckbox,
    vSelect,
    BOverlay,
    BAvatar,
    flatPickr,
    quillEditor,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewTaskSidebarActive",
    event: "update:is-add-new-task-sidebar-active",
  },
  props: {
    isAddNewTaskSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    clientOptions: {
      type: Array,
      required: true,
    },
    projectOptions: {
      type: Array,
      required: true,
    },
    contractOptions: {
      type: Array,
      required: true,
    },
    tasktagOptions: {
      type: Array,
      required: true,
    },
    taskstageOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      max,
    };
  },
  methods: {
    getContracts() {
      return this.contractOptions.filter(
        (item) => this.taskData.client_id == item.client_id
      );
    },
    getProjects() {
      return this.projectOptions.filter(
        (item) => this.taskData.client_id == item.client_id
      );
    },
  },
  setup(props, { emit }) {
    const loading = ref(false);

    const blankTaskData = {
      id: 0,
      deadline_bit: true,
      deadline_date: "",
      client_id: null,
      project_id: null,
      contract_id: null,
      users: null,
      title: "",
      content: "",
      task_stage_id: 1,
      task_tag_id: null,
      is_important: false,
    };
    const button_text = ref("新增");

    const taskData = ref(JSON.parse(JSON.stringify(blankTaskData)));

    const resettaskData = () => {
      taskData.value = JSON.parse(JSON.stringify(blankTaskData));
    };

    const toast = useToast();

    const editorOption = {
      modules: {
        toolbar: "#quill-toolbar",
      },
      placeholder: "Write your task description",
    };

    watch(
      () => [props.id, props.isAddNewTaskSidebarActive],
      () => {
        if (props.id > 0 && props.isAddNewTaskSidebarActive) {
          taskData.value.id = props.data.id;
          taskData.value.task_tag_id = props.data.task_tag_id;
          taskData.value.deadline_date = props.data.deadline_date;
          taskData.value.client_id = props.data.client_id;
          taskData.value.project_id = props.data.project_id;
          taskData.value.contract_id = props.data.contract_id;
          taskData.value.users = props.data.users;
          taskData.value.title = props.data.title;
          taskData.value.content = props.data.content;
          taskData.value.task_stage_id = props.data.task_stage_id;
          taskData.value.is_important =
            props.data.is_important == 1 ? true : false;

          taskData.value.deadline_bit =
            props.data.deadline_bit == 1 ? true : false;

          if (taskData.value.id > 0) {
            button_text.value = "修改";
          } else {
            button_text.value = "新增";
          }
        } else {
          button_text.value = "新增";
        }
      }
    );

    const onSubmit = () => {
      loading.value = true;
      var users = [];
      taskData.value.users.forEach((item) => {
        if (typeof item == "object") {
          users.push(item.id);
        } else {
          users.push(item);
        }
      });
      taskData.value.users = users;
      store
        .dispatch("task/addTask", taskData.value)
        .then((response) => {
          loading.value = false;
          emit("update:is-add-new-task-sidebar-active", false);
          emit("refetch-data");
          toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: response.data.message,
              icon: "CheckIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          loading.value = false;
          toast({
            component: ToastificationContent,
            props: {
              title: "Fail",
              text: error.response.data.message,
              icon: "HeartIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resettaskData);

    return {
      taskData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      button_text,
      loading,
      editorOption,
      avatarText,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>

<style lang="scss">
#add-new-task-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.ql-editor {
  min-height: 200px;
}
</style>
