import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";
import { title } from "@core/utils/filter";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ability from "@/libs/acl/ability";

export default function useTasksList() {
  //User toast
  const toast = useToast();

  const refTaskListTable = ref(null);

  const tableColumns = [
    /* {key: 'id', label:'工作編號', sortable: true}, */
    { key: "client", label: "客戶名稱" },
    { key: "title", stickyColumn: true, label: "工作標題", sortable: true },
    { key: "creator.name", label: "指派者" },
    { key: "users", label: "指定員工" },
    { key: "tag", label: "標籤" },
    { key: "stage", label: "狀態" },
    { key: "deadline_date", label: "限期", sortable: true },
    { key: "actions", label: "操作" },
  ];

  //Filter
  const clientFilter = ref(null);
  const dateFromFilter = ref(null);
  const dateToFilter = ref(null);
  const deleteFilter = ref(null);
  const taskstageFilter = ref(null);
  const tasktagFilter = ref(null);
  const sortFilter = ref(false);
  const adminFilter = ref(false);

  const perPage = ref(10);
  const totalTasks = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);

  const dataMeta = computed(() => {
    const localItemsCount = refTaskListTable.value
      ? refTaskListTable.value.localItems.length
      : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalTasks.value,
    };
  });

  const refetchData = () => {
    refTaskListTable.value.refresh();
  };

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      clientFilter,
      dateFromFilter,
      dateToFilter,
      deleteFilter,
      taskstageFilter,
      tasktagFilter,
      sortFilter,
      adminFilter,
    ],
    () => {
      refetchData();
    }
  );

  const fetchTasks = (ctx, callback) => {
    store
      .dispatch("task/fetchTasks", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        client_id: clientFilter.value,
        date_from: dateFromFilter.value,
        date_to: dateToFilter.value,
        is_deleted: deleteFilter.value,
        task_stage_id: taskstageFilter.value,
        task_tag_id: tasktagFilter.value,
        custom_sorting: sortFilter.value,
        is_admin: adminFilter.value,
      })
      .then((response) => {
        const { tasks, total } = response.data;
        callback(tasks);
        totalTasks.value = total;
      })
      .catch((error) => {
        console.log(error);
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching task list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  return {
    fetchTasks,
    tableColumns,
    perPage,
    currentPage,
    totalTasks,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTaskListTable,
    ability,
    refetchData,
    clientFilter,
    dateFromFilter,
    dateToFilter,
    deleteFilter,
    taskstageFilter,
    tasktagFilter,
    sortFilter,
    adminFilter,
  };
}
